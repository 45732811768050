<template>
  <viewcard--c title="Estudios" :btback="{}" :busy="isloading">
    <Table :fields="fields" :list="list" border="full" responsive>
      <template #homeoffice="data" style="width: 1px !important">
        <div class="text-nowrap notify-status">
          <b-avatar
            :variant="data.item.homeoffice ? 'success' : 'danger'"
            :id="`scheduled-description-${data.item.id}`"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.homeoffice ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </div>
      </template>
      <template #presential="data" style="width: 1px !important">
        <div class="text-nowrap notify-status">
          <b-avatar
            :variant="data.item.presential ? 'success' : 'danger'"
            :id="`presential-description-${data.item.id}`"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.presential ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </div>
      </template>
      <template #streaming="data" style="width: 1px !important">
        <div class="text-nowrap notify-status">
          <b-avatar
            :variant="data.item.streaming ? 'success' : 'danger'"
            :id="`streaming-description-${data.item.id}`"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.streaming ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </div>
      </template>
      <template #active="data" style="width: 1px !important">
        <div class="text-nowrap notify-status">
          <b-avatar
            :variant="data.item.active ? 'success' : 'danger'"
            :id="`scheduled-description-${data.item.id}`"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.active ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </div>
      </template>
      <template #actions="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`edit-studio-row-${data.item.id}`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="onClickSave(data.item)"
          />
          <b-tooltip
            v-if="data.index > 0"
            title="Editar Studio"
            :target="`edit-studio-row-${data.item.id}`"
          />
        </div>
      </template>
    </Table>

    <b-sidebar
      ref="coursesDetailsSideBar"
      id="coursesDetailsSideBar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isSave"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isSave">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Editar Studio</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <studio-form @result="onResult" :dto="dto" />
      </template>
    </b-sidebar>
  </viewcard--c>
</template>
<script>
import Table from "@/components/Table.vue";
import studio from "./studio";
import _studiosService from "@/services/studios-service";
import { VBToggle } from "bootstrap-vue";

export default {
  components: {
    Table,
    "studio-form": studio,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isloading: false,
      fields: [
        { key: "name", label: "Nome", orderBy: { type: "front" } },
        { key: "homeoffice", label: "Home Office", orderBy: { type: "front" } },
        { key: "presential", label: "Presencial", orderBy: { type: "front" } },
        {
          key: "streaming",
          label: "Somente Transmissão",
          orderBy: { type: "front" },
        },
        { key: "active", label: "Ativo", orderBy: { type: "front" } },
        { key: "actions", label: "Ações" },
      ],
      list: [],
      isSave: false,
      dto: null,
    };
  },
  created() {
    this.getRecords();
  },
  methods: {
    getRecords() {
      this.isloading = true;
      _studiosService
        .show(this.$route.params.studiogroupid)
        .then((res) => {
          if (res.content) {
            this.list = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onClickSave(_record) {
      this.dto = {
        studios_group_id: _record
          ? _record.studios_group_id
          : this.$route.params.studiogroupid,
        id: _record ? _record.id : 0,
      };
      this.isSave = true;
    },
    onResult(_res) {
      this.isSave = false;
      this.dto = null;
    },
  },
};
</script>